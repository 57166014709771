import React, { useState, useRef } from "react";
import "./Collapsible.css";
import PropTypes from "prop-types";
import styles from "../styles.js";


const Collapsible = ({ button, punc, hiddenContent, linkColour }) => {
  const [setActive, setActiveState] = useState("");
  const [setHeight, setHeightState] = useState("0px");
  const [setWidth, setWidthState] = useState("0px");
  const [setButtonDisplay, setButtonDisplayState] = useState("inline");
  const [setCollapsibleDisplay, setCollapsibleDisplayState] =
    useState("inline-block");

  const content = useRef(null);

  const toggleCollapsible = () => {
    setActiveState(setActive === "" ? "active" : "");
    setHeightState(
      setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
    );
    setWidthState(setActive === "active" ? "0px" : "100%");
    setButtonDisplayState(setActive === "active" ? "inline" : "inline");
    setCollapsibleDisplayState(
      setActive === "active" ? "inline-block" : "block"
    );
  };

  return (
    <React.Fragment>
      <div
        style={{
          display: `${setButtonDisplay}`,
          whiteSpace: "normal",
        }}
        className={`collapsible ${setActive}`}
        onClick={() => {
          toggleCollapsible();
        }}
      >
        <span style={styles.HomeLink} className="collapsible__title">
          <span
            style={{
              color: `${linkColour}`,
              textDecorationColor: `${linkColour}`,
              textDecoration: `${"underline"}`,
            }}
          >
            {button}
          </span>
        </span>
        <span style={styles.HomeParagraph}>{punc}</span>
      </div>
      <div
        className="collapsible__section"
        style={{
          display: `${setCollapsibleDisplay}`,
        }}
      >
        <div
          ref={content}
          style={{
            maxHeight: `${setHeight}`,
            maxWidth: `${setWidth}`,
          }}
          className="collapsible__content"
        >
          <div
            style={
              {
                // marginLeft: `${styles.HomeIndent}`,
                // marginRight: `${styles.HomeIndent}`,
              }
            }
            className="collapsible__text"
            // dangerouslySetInnerHTML={{ __html: hiddenContent }}
          >
            {hiddenContent}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

Collapsible.propTypes = {
  button: PropTypes.string,
  punc: PropTypes.string,
  hiddenContent: PropTypes.element,
  linkColour: PropTypes.string,
  clickMethod: PropTypes,
};

export default Collapsible;
