import React, { useState } from "react";
import LogoFooter from "../../components/LogoFooter";
import AlexanderSheridanLink from "../../components/AlexanderSheridanLink";
import Collapsible from "../../components/Collapsible";
import ContactToggle from "../../components/ContactToggle";
import Contact from "../../components/Contact";
import AIA from "../../images/logos/AIA.png";
import ARBV from "../../images/logos/ARBV.png";
import {
  MainContainer,
  projectsColour,
  exhibitionsColour,
  publicationsColour,
  lecturesColour,
  awardsColour,
  HomeBody,
  HomeParagraph,
  FirstParagraph,
  HomeMainLink,
  CollapsibleOuter,
  ContactPlain,
  ContactReverse,
  Logo1,
  Logo2,
  LogoContainer,
  LogoInnerContainer,
  Wrap,
  WrapSpan,
} from "./Home.style";

import { ProjectsContent } from "./projects/ProjectsContent";
import { ExhibitionsContent } from "./exhibitions/ExhibitionsContent";
import { LecturesContent } from "./lectures/LecturesContent";
import { PublicationsContent } from "./publications/PublicationsContent";
import { AwardsContent } from "./awards/AwardsContent";

const Home = () => {
  // const [openProjects, setOpenProjects] = useState(false);
  // const [openExhibitions, setOpenExhibitions] = useState(false);
  // const [openLectures, setOpenLectures] = useState(false);
  // const [openPublications, setOpenPublications] = useState(false);
  // const [openAwards, setOpenAwards] = useState(false);

  /*
    onClick of projects when false, set projects state to true, set all others to false.
    onClick of projects when true, set projects state to false, set all others to false.
  */
  // function onClickProjects() {
  //   setOpenExhibitions(false);
  //   setOpenLectures(false);
  //   setOpenPublications(false);
  //   setOpenAwards(false);

  //   console.log(
  //     "openProjects:",
  //     { openProjects },
  //     "\n",
  //     "openExhibitions:",
  //     { openExhibitions },
  //     "\n",
  //     "openLectures:",
  //     { openLectures },
  //     "\n",
  //     "openPublications:",
  //     { openPublications },
  //     "\n",
  //     "openAwards:",
  //     { openAwards }
  //   );
  // }

  const handleClick = (event) => {
    console.log(`hihii`);

    // event.stopPropagation();
    // this.setOpenProjects(openProjects ? false : true);
    // console.log(`Home.js -- openProjects:${openProjects}`);
  };

  const ProjectsCollapsible = () => (
    /*
    HEY LOOKS LIEK COLLAPSIBLE OUTER IS GONNA WORK! YOU CAN DO DIS HEE
    */

    <CollapsibleOuter onClick={(event) => handleClick(event)}>
      <Collapsible
        button="projects"
        id="projects"
        punc={",\u00a0"}
        linkColour={projectsColour}
        hiddenContent={ProjectsContent}
        // onClick={(onClickProjects) => {
        //   this.setOpenProjects(openProjects ? false : true);
        //   console.log(`Home.js -- openProjects:${openProjects}`);
        // }}
      />
    </CollapsibleOuter>
  );

  const ExhibitionsCollapsible = () => (
    <Collapsible
      button="exhibitions"
      id="exhibitions"
      punc={",\u00a0"}
      linkColour={exhibitionsColour}
      hiddenContent={ExhibitionsContent}
    />
  );

  const LecturesCollapsible = () => (
    <Collapsible
      button="lectures and broadcasting"
      id="lectures-and-broadcasting"
      punc={".\u00a0"}
      linkColour={lecturesColour}
      hiddenContent={LecturesContent}
    />
  );

  const PublicationsCollapsible = () => (
    <Collapsible
      button="publications"
      id="publications"
      punc={",\u00a0"}
      linkColour={publicationsColour}
      hiddenContent={PublicationsContent}
    />
  );

  const AwardsCollapsible = () => (
    <Collapsible
      button="awards"
      id="awards"
      punc={",\u00a0"}
      linkColour={awardsColour}
      hiddenContent={AwardsContent}
    />
  );

  const Instagram = (
    <>
      instagram at{" "}
      <HomeMainLink
        href="https://www.instagram.com/a_s_architecture/"
        target="_blank"
        style={{
          textDecoration: "none",
        }}
      >
        @<u>a</u>_<u>s</u>_<u>architecture</u>
      </HomeMainLink>
    </>
  );

  const Phone = (
    <>
      <ContactPlain>call at </ContactPlain>
      <HomeMainLink>0410-639-150</HomeMainLink>
      <ContactPlain> (Ben) or </ContactPlain>
      <HomeMainLink>0410-269-095</HomeMainLink>
      <ContactPlain> (Jacqui)</ContactPlain>
    </>
  );

  const Email = (
    <>
      <ContactPlain>line at </ContactPlain>
      <HomeMainLink href="mailto:info@alexandersheridan.com">
        <Wrap>
          <WrapSpan>info</WrapSpan>
          <WrapSpan>@</WrapSpan>
          <WrapSpan>alexander</WrapSpan>
          <WrapSpan>sheridan</WrapSpan>
          <WrapSpan>.</WrapSpan>
          <WrapSpan>com</WrapSpan>
        </Wrap>
      </HomeMainLink>
      {/* <HomeMainLink href="mailto:ben@alexandersheridan.com">
        <Wrap>
          <WrapSpan>ben</WrapSpan>
          <WrapSpan>@</WrapSpan>
          <WrapSpan>alexander</WrapSpan>
          <WrapSpan>sheridan</WrapSpan>
          <WrapSpan>.</WrapSpan>
          <WrapSpan>com</WrapSpan>
        </Wrap>
      </HomeMainLink>
      <ContactPlain> or </ContactPlain>
      <HomeMainLink href="mailto:jacqui@alexandersheridan.com">
        <Wrap>
          <WrapSpan>jacqui</WrapSpan>
          <WrapSpan>@</WrapSpan>
          <WrapSpan>alexander</WrapSpan>
          <WrapSpan>sheridan</WrapSpan>
          <WrapSpan>.</WrapSpan>
          <WrapSpan>com</WrapSpan>
        </Wrap>
      </HomeMainLink> */}
    </>
  );

  return (
    <MainContainer>
      <HomeBody>
        <HomeParagraph>
          <FirstParagraph>
            <AlexanderSheridanLink /> is an award-winning architecture and
            research collaboration between Jacqui Alexander and Ben Sheridan,
            based in Melbourne, Australia. We adopt an expanded approach to
            architectural practice, delivering design outcomes at a range of
            scales.
          </FirstParagraph>
        </HomeParagraph>

        <HomeParagraph>
          We have over a decade of experience in the design and construction of
          a broad range of <ProjectsCollapsible />
          including new houses, home alterations & additions, interiors,
          multiple-housing, education, commercial and retail, and urban design.
          We value the civic contribution that architecture can make and its
          role in enhancing a sense of place. The unique opportunities and
          constraints of each brief and site inform our design response.
        </HomeParagraph>

        <HomeParagraph>
          Our design studio is complemented by a parallel research practice,
          that applies architectural tools and knowledge to diverse contexts and
          pressing challenges. We have a strong interest in the relationship
          between architecture and contemporary culture, emerging technologies,
          and materials, and the role of design in affecting positive social
          outcomes. We experiment with new concepts and make our work public
          through speculative design, <ExhibitionsCollapsible />
          education, writing, public <LecturesCollapsible />
        </HomeParagraph>

        <HomeParagraph>
          Our projects appear in a range of popular, architectural and scholarly{" "}
          <PublicationsCollapsible />
          and have been exhibited at leading local and international art and
          design museums. The practice has been recognised through a number of{" "}
          <AwardsCollapsible />
          and in 2021 received the Australian Institute of Architects’ Emerging
          Architect Prize (Vic). To discuss your project or for any other
          queries, drop us a{" "}
          <Contact button="line" hiddenContent={Email} linkColour="blue" />, or
          give us a{" "}
          <Contact button="call" hiddenContent={Phone} linkColour="blue" />. You
          can follow us on{" "}
          <Contact
            button="instagram"
            hiddenContent={Instagram}
            linkColour="blue"
          />
          .{"  "}
          <LogoContainer>
            <LogoInnerContainer>
              <Logo1 src={AIA}></Logo1>
            </LogoInnerContainer>
            <LogoInnerContainer>
              <Logo2 src={ARBV}></Logo2>
            </LogoInnerContainer>
          </LogoContainer>
        </HomeParagraph>
      </HomeBody>
      {/* <LogoFooter /> */}
    </MainContainer>
  );
};

export default Home;
