import { LecturesList } from "./LecturesList";
import {
  LecturesLink,
  HomeBulletList,
  HomeBulletItem,
  HomeBulletSymbol,
  HomeBulletItemText,
} from "../Home.style";

export const LecturesContent = (
  <HomeBulletList>
    {LecturesList.map((item, index) => (
      <HomeBulletItem>
        <HomeBulletSymbol>•</HomeBulletSymbol>
        <HomeBulletItemText>
          <LecturesLink
            key={index}
            href={item.href}
            to={item.to}
            target={item.target}
          >
            {item.title}
          </LecturesLink>
        </HomeBulletItemText>
      </HomeBulletItem>
    ))}
  </HomeBulletList>
);
