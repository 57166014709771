import React from "react";
import Home from "./pages/home/Home";
import "./App.css";
import styled from "styled-components";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import EuroaHouse from "./pages/projects/EuroaHouse";
import TenancyTitle from "./pages/projects/TenancyTitle";
import Pavilion from "./pages/projects/PavilionHouse";
import Supershared from "./pages/projects/Supershared";
import Yarraville from "./pages/projects/Yarraville";
// import HighStreet from "./pages/projects/HighStreet";
// import RoofHouse from "./pages/projects/RoofHouse";
import Fitzroy from "./pages/projects/Fitzroy";

import ThisTime from "./pages/exhibitions/ThisTime";
import Dwelling from "./pages/exhibitions/Dwelling";
import DwellingPub from "./pages/publications/DwellingPub";

import Example from "./pages/example/Example";

const Body = styled.div`
  min-height: 100vh;
  font-family: sans-serif;
`;

function App() {
  return (
    <Body>
      <Router>
        <Switch>
          <Route
            path="/projects/euroa-house"
            component={EuroaHouse}
            title="testtitle"
          />
          <Route
            path="/projects/tenancy-title"
            component={TenancyTitle}
            title="testtitle"
          />
          <Route
            path="/projects/pavilion-house"
            component={Pavilion}
            title="testtitle"
          />
          <Route
            path="/projects/supershared"
            component={Supershared}
            title="testtitle"
          />
          <Route
            path="/projects/yarraville-loft"
            component={Yarraville}
            title="testtitle"
          />
          {/* <Route
            path="/projects/high-street"
            component={HighStreet}
            title="testtitle"
          /> */}
          {/* <Route
            path="/projects/roof-house"
            component={RoofHouse}
            title="testtitle"
          /> */}
          <Route
            path="/projects/fitzroy-extension"
            component={Fitzroy}
            title="testtitle"
          />
          <Route
            path="/exhibitions/this-time-tomorrow"
            component={ThisTime}
            title="example"
          />
          <Route
            path="/publications/dwelling-on-the-platform"
            component={DwellingPub}
            title="example"
          />
          <Route
            path="/exhibitions/dwelling-on-the-platform"
            component={Dwelling}
            title="example"
          />          
          <Route path="/example/example" component={Example} title="example" />
          <Route path="/" component={Home} />
        </Switch>
      </Router>
    </Body>
  );
}

export default App;
