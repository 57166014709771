export const ProjectsList = [
  {
    title: "Euroa House",
    href: "/projects/euroa-house",
    to: "/",
    target: "_self",
  },
  {
    title: "Tenancy ≠ Title",
    href: "/projects/tenancy-title",
    to: "/projects/tenancy-title",
    target: "_self",
  },
  {
    title: "Pavilion House",
    href: "/projects/pavilion-house",
    to: "/projects/pavilion-house",
    target: "_self",
  },
  {
    title: "Supershared",
    href: "/projects/supershared",
    to: "/projects/supershared",
    target: "_self",
  },
  {
    title: "Yarraville Loft",
    href: "/projects/yarraville-loft",
    to: "/projects/yarraville-loft",
    target: "_self",
  },
  // {
  //   title: "High Street",
  //   href: "/projects/high-street",
  //   to: "/projects/high-street",
  //   target: "_self",
  // },
  // {
  //   title: "Roof House",
  //   href: "/projects/roof-house",
  //   to: "/projects/roof-house",
  //   target: "_self",
  // },
  {
    title: "Fitzroy Extention",
    href: "/projects/fitzroy-extension",
    to: "/projects/fitzroy-extension",
    target: "_self",
  },
];