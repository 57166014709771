import React from "react";
import {
  useDocumentTitle,
  ProjectAlexanderSheridanHeader,
  MainContainer,
  PostParagraph,
  FirstPostParagraph,
  HomeParagraph,
  PostParagraphContainer,
  PostTitleContainer,
  CreditLine,
  CreditLineContainer,
} from "../project-page/ProjectPageElements.js";
import Footer from "../../components/Footer";
import PostSlider from "../../components/post/PostSlider.js";

import image1 from "../../images/exhibitions/thistime/1_Piccadilly.png";
import image2 from "../../images/exhibitions/thistime/2_Regent.png";
import image3 from "../../images/exhibitions/thistime/3_Mayfair.png";
import image4 from "../../images/exhibitions/thistime/4_Piccadilly.png";
import image5 from "../../images/exhibitions/thistime/5_Trafalgar.png";
import image6 from "../../images/exhibitions/thistime/6_Greenwich.png";

const PostTitle = "This Time Tomorrow";

const images = [
  {
    image: image1,
    caption: "Selfie at Piccadilly Circus",
  },
  {
    image: image2,
    caption: "“Nash Apartments” on Regent",
  },
  {
    image: image3,
    caption: "Hill Street, Mayfair",
  },
  {
    image: image4,
    caption: "“Cirque” at Piccadilly",
  },
  {
    image: image5,
    caption: "“Burberry Towers”: view from Trafalgar Square",
  },
  {
    image: image6,
    caption: "“Power Apartments”, Greenwich Power Station",
  },
];

const ThisTime = () => {
  useDocumentTitle(PostTitle);
  return (
    <MainContainer>
      <HomeParagraph>
        <ProjectAlexanderSheridanHeader />{" "}
        <PostTitleContainer>{PostTitle}</PostTitleContainer> by POST & Monash
        Art Projects, is a parafictional image-based work, developed for the
        Victoria and Albert Museum in response to the 2014 London Festival of
        Architecture’s theme, ‘Capital’.
      </HomeParagraph>
      <PostSlider images={images}></PostSlider>
      <PostParagraphContainer>
        <FirstPostParagraph>
          <em>This Time Tomorrow</em> critiques the UK’s capital gains tax
          exemptions, which have incentivized the development of luxury
          apartment towers in central London, marketed to world’s wealthiest,
          and the role of digital media in amplifying these urban processes.
        </FirstPostParagraph>
        <PostParagraph>
          A series of hyperreal images and constructed ‘selfies’ capture
          familiar picture-postcard scenes in central London, disrupted by
          profane residential development. Taking cues from the montage
          practices of ‘Radical era’ architects like Superstudio and Archizoom,
          the images depict absurdist towers grafted onto London’s sacrosanct
          sites. However, unlike the abstract, analogue montage approaches of
          the 1960s and 70s, today’s widespread accessibility of post-production
          photo-editing software means that absurdist fictions might be
          experienced on some level as fact. These images were published in a
          satirical online article in POST Magazine, discussing the very serious
          effects of foreign property investment on the city, and were
          subsequently picked up by a number of online news outlets and print
          magazines, moving some to protest, while others wanted to invest.
          Clues exist within <em>This Time Tomorrow</em>’s imagery which require
          unpicking by the audience: for example, if the towers are under
          construction, they can’t be simulations, because architectural
          renderings as a rule depict impressions of completed, constructed
          buildings. In this way, the images challenge audiences to discern not
          only which parts are fabricated, but also which parts are true.
        </PostParagraph>
        <PostParagraph>
          Ultimately, <em>This Time Tomorrow</em> explores the affective
          potential of the viral image in the age of social media, and the
          capacity for architects, as producers of visual culture, to
          appropriate platform technology as a means of rebellion, growing
          global networks to contest the financialisation of housing and cities
          worldwide.
        </PostParagraph>
      </PostParagraphContainer>
      <CreditLineContainer>
        <CreditLine>
          Project leaders:{" "}
          <em>Jacqui Alexander, Ben Sheridan, Andre Bonnice</em>
        </CreditLine>
        <CreditLine>
          Collaborators:{" "}
          <em>Hajley Petein, Dan Salmon, Carolyne Groves, James Bickford</em>
        </CreditLine>
      </CreditLineContainer>

      <Footer />
    </MainContainer>
  );
};

export default ThisTime;
