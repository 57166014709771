import React from "react";
import {
  useDocumentTitle,
  ProjectAlexanderSheridanHeader,
  MainContainer,
  PostParagraph,
  FirstPostParagraph,
  HomeParagraph,
  PostParagraphContainer,
  PostTitleContainer,
  ProjectLinkContainer,
  NextProjectContainer,
  NextProjectLink,
  NextProjectSymbol,
} from "../project-page/ProjectPageElements.js";
import Footer from "../../components/Footer";
import PostSlider from "../../components/post/PostSlider.js";

import image1 from "../../images/projects/pavilion/1_Sectional Perspective_BBQ-ed.jpg";
import image2 from "../../images/projects/pavilion/2_Sectional Perspective_corridor.jpg";
import image3 from "../../images/projects/pavilion/3_Sectional Perspective_AA.jpg";
import image4 from "../../images/projects/pavilion/4_Ground_Floor_PavilionHouse_edit.jpg";
import image5 from "../../images/projects/pavilion/5_Pavilion House Updated Oblique.jpg";
import image6 from "../../images/projects/pavilion/6_lineweights.jpg";

import { ProjectsList } from "../home/projects/ProjectsList.js";

const ProjectIndex = ProjectsList.findIndex(
  (p) => p.href === "/projects/pavilion-house"
);

const PostTitle = ProjectsList[ProjectIndex].title;

const images = [
  {
    image: image1,
    caption: "Sectional view: through courtyard",
  },
  {
    image: image2,
    caption:
      "Sectional view: nested volumes and a family of furniture elements",
  },
  {
    image: image3,
    caption: "Sectional view: showing double height volume",
  },
  {
    image: image4,
    caption: "Ground Floor Plan",
  },
  {
    image: image5,
    caption: ["Plan Oblique. ", <em>Image credits: George Mellos</em>],
  },
  {
    image: image6,
    caption: [
      "A series of furniture elements mediate access to space. ",
      <em>Image credits: George Mellos</em>,
    ],
  },
];

const PavilionHouse = () => {
  useDocumentTitle(PostTitle);
  return (
    <MainContainer>
      <HomeParagraph>
        <ProjectAlexanderSheridanHeader />{" "}
        <PostTitleContainer>{PostTitle}</PostTitleContainer> is a 100m2 post -
        nuclear domestic prototype, designed to promote home sharing in the
        broadest sense, and address the need for more diverse housing to suit
        Australia’s shrinking households.
      </HomeParagraph>
      <PostSlider images={images}></PostSlider>
      <PostParagraphContainer>
        <FirstPostParagraph>
          Developed for a test site in West Footscray, Pavilion House reimagines
          the single dwelling typology as a series of dispersed rooms arranged
          around a courtyard for maximum flexibility. Treating rooms as a series
          of discrete, independent elements within a larger shell, and providing
          multiple points of access from the garden, creates a sense of porosity
          through the house that challenges traditional notions of privacy, and
          allows more or less space to be leased out as-needs. In this way,
          Pavilion House reimagines the single dwelling typology for compact,
          elastic and on-demand living.
        </FirstPostParagraph>
        <PostParagraph>
          A family of bespoke furniture elements are designed to mediate access
          to different parts of the house, create shared experiences and
          facilitate dynamic and creative adaptation of the space by residents.
          Through a blurring of inside and outside, public and private, the
          design is able to flexibly accommodate a range of household
          configurations including a small family, adults with dependent or a
          host and guest, and is intended to address the growing number of
          shrinking Australian household types — including single mothers,
          elderly and lone person households — for whom there are few affordable
          and ‘right-sized’ housing options.
        </PostParagraph>
      </PostParagraphContainer>

      <ProjectLinkContainer>
        <HomeParagraph>
          <NextProjectContainer>
            <NextProjectLink href={ProjectsList[ProjectIndex + 1].href}>
              {ProjectsList[ProjectIndex + 1].title}
            </NextProjectLink>
            <NextProjectSymbol />
          </NextProjectContainer>
        </HomeParagraph>
      </ProjectLinkContainer>

      <Footer />
    </MainContainer>
  );
};

export default PavilionHouse;
