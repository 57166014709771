import postPopUp from "../../../images/publications/post-covers.jpg";
import fwPopUp from "../../../images/publications/ja_fw_cover.png";
import aaPopUp from "../../../images/publications/aa_cover.jpg";
import dkPopUp from "../../../images/publications/dk_cover.jpg";
import dwellingPopUp from "../../../images/publications/dwelling/1_Dwellingontheplatform.jpg";

import pdfFutureWest from "../../../pdfs/publications/FutureWest.pdf";
import pdfDasKunstmagazin from "../../../pdfs/publications/io39-08_JL_S140_141-3.pdf";

export const PublicationsList = [
  {
    title: "Dwelling on the Platform",
    href: "/publications/dwelling-on-the-platform",
    to: "/publications/dwelling-on-the-platform",
    target: "",
    image: { src: dwellingPopUp, alt: "Dwelling on the Platform cover" },
  },
  {
    title: "POST Magazine",
    href: "http://postmagazine.org",
    to: "/",
    target: "_blank",
    image: { src: postPopUp, alt: "Post Magazine cover" },
  },
  {
    title: "Architecture Australia",
    href: "https://architectureau.com/contributors/Alexander-Jacqui/",
    to: "/",
    target: "_blank",
    image: { src: aaPopUp, alt: "Architecture Australia magazine cover" },
  },
  {
    title: "Future West: Australian Urbanism",
    href: pdfFutureWest,
    to: "/",
    target: "_blank",
    image: { src: fwPopUp, alt: "Future West Magazine cover" },
  },
  {
    title: "Das Kunstmagazin",
    href: pdfDasKunstmagazin,
    target: "_blank",
    image: { src: dkPopUp, alt: "Das Kunstmagazin cover" },
  },
  {
    title: "The Design Journal",
    target: "_blank",
  },
  {
    title: "Journal of Urban Science",
    target: "_blank",
  },
];
