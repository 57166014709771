const HomeFontSize = "2.74rem";
const HomeIndent = "14rem";
const HomeIndentM = "7rem";
const PostComponentMargin = "3rem";
const LinkBlue = "Blue";
const HomeFontFamily = "Karelia-Regular";
const AlexanderSheridanFont = "Karelia-Outtakes";

const HomeParagraph = {
  fontFamily: HomeFontFamily,
  margin: "0 0",
  fontSize: HomeFontSize,
  lineHeight: "3.05rem",
  letterSpacing: "0.005rem",
  maxWidth: "100vw",
  wordSpacing: "0.05rem",
  textIndent: "0rem",
};

const PostBigParagraph = {
  fontFamily: HomeFontFamily,
  margin: "0 0",
  fontSize: HomeFontSize,
  lineHeight: "3.05rem",
  letterSpacing: "0.005rem",
  maxWidth: "100vw",
  wordSpacing: "0.05rem",
  textIndent: "0rem",
  marginBottom: "1rem",
};

const HomeLink = {
  fontFamily: HomeFontFamily,
  fontSize: HomeFontSize,
  color: LinkBlue,
  textDecoration: "underline",
  // whiteSpace: "nowrap",
  margin: "0 0",

  lineHeight: "3.05rem",
  letterSpacing: "0.005rem",
  maxWidth: "100vw",
  wordSpacing: "0.05rem",
  textIndent: "0rem",
};

const PostTitleContainer = {
  textDecoration: "underline",
};

const PostParagraphContainer = {
  hyphens: "none",
  marginLeft: HomeIndent,
  marginRight: HomeIndent,
  marginBottom: "6rem",
  columnCount: "2",
  columnGap: "1.3rem",
};

const PostParagraphContainerM = {
  hyphens: "auto",
  marginRight: PostComponentMargin,
  marginLeft: PostComponentMargin,
  marginBottom: "6rem",
  columnCount: "1 !important1",
};

const PostParagraph = {
  fontFamily: "IBM Plex Serif",
  textIndent: "4rem",
  fontSize: "0.95rem",
};
const PostParagraphM = {
  fontFamily: "IBM Plex Serif",
  textIndent: "4rem",
  fontSize: "1.5rem",
};

export const styles = {
  HomeParagraph: HomeParagraph,
  PostBigParagraph: PostBigParagraph,
  HomeIndent: HomeIndent,
  HomeIndentM: HomeIndentM,
  PostTitleContainer: PostTitleContainer,
  PostParagraph: PostParagraph,
  PostParagraphM: PostParagraphM,
  PostParagraphContainer: PostParagraphContainer,
  PostParagraphContainerM: PostParagraphContainerM,
  PostComponentMargin: PostComponentMargin,
  LinkBlue: LinkBlue,
  HomeLink: HomeLink,
  AlexanderSheridanFont: AlexanderSheridanFont,
  HomeFontFamily: HomeFontFamily,
  HomeFontSize: HomeFontSize,
};

export default styles;
