import { AwardsList } from "./AwardsList";
import {
  AwardsLink,
  HomeBulletList,
  HomeBulletItem,
  HomeBulletSymbol,
  HomeBulletItemText,
} from "../Home.style";

export const AwardsContent = (
  <HomeBulletList>
    {AwardsList.map((item, index) =>
      item.href != null ? (
        <HomeBulletItem>
          <HomeBulletSymbol>•</HomeBulletSymbol>
          <HomeBulletItemText>
            {item.subtitle}
            <AwardsLink
              key={index}
              href={item.href}
              to={item.to}
              target={item.target}
            >
              {item.title}
            </AwardsLink>
          </HomeBulletItemText>
        </HomeBulletItem>
      ) : (
        <HomeBulletItem>
          <HomeBulletSymbol>•</HomeBulletSymbol>
          <HomeBulletItemText key={index}>
            {item.subtitle}
            {item.title}
          </HomeBulletItemText>
        </HomeBulletItem>
      )
    )}
  </HomeBulletList>
);