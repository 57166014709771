import { PublicationsList } from "./PublicationsList";
import {
  PublicationsLink,
  HomeBulletList,
  HomeBulletItem,
  HomeBulletSymbol,
  HomeBulletItemText,
  PopUpImage,
} from "../Home.style";

export const PublicationsContent = (
  <HomeBulletList>
    {PublicationsList.map((item, index) => (
      <HomeBulletItem>
        <HomeBulletSymbol>•</HomeBulletSymbol>
        <HomeBulletItemText>
          {item.href ? (
            <PublicationsLink
              key={index}
              href={item.href}
              to={item.to}
              target={item.target}
            >
              {item.title}
              {item.image && (
                <PopUpImage>
                  <img
                    src={item.image.src}
                    alt={item.image.alt}
                    unselectable="on"
                  />
                </PopUpImage>
              )}
            </PublicationsLink>
          ) : (
            item.title
          )}
        </HomeBulletItemText>
      </HomeBulletItem>
    ))}
  </HomeBulletList>
);
