import React from "react";
import styled from "styled-components";

import AIA from "../images/logos/AIA.png";
import ARBV from "../images/logos/ARBV.png";

const Container = styled.header`
  margin-top: auto;
  display: flex;
  justify-content: flex-end;
`;

const Logo = styled.img`
  height: 3rem;
  margin-top: 0.3rem;
  margin-left: 2rem;
`;

const LogoFooter = () => {
  return (
    <Container>
      <Logo src={AIA}></Logo>
      <Logo src={ARBV}></Logo>
    </Container>
  );
};

export default LogoFooter;
