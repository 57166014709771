import React from "react";
import styled from "styled-components";
import styles from "../../styles.js";
import PropTypes from "prop-types";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const OuterSliderContainer = styled.div`
  /* display: flex;
  align-items: center;
  justify-content: center; */
  margin-top: ${styles.PostComponentMargin};
  margin-bottom: ${styles.PostComponentMargin};
`;

const InnerSliderContainer = styled.div`
  /* background-color: fuchsia; */

  margin: 0 auto;
`;

const SliderImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SliderImage = styled.img`
  /* display: inline-block; */
  //height: 100% !important;
  object-fit: contain !important;
  border: 0;
  outline: none;
`;

const SliderCaption = styled.p`
  ${styles.PostParagraphM}
  text-align: center;
  text-indent: 0rem !important;
  font-weight: 400;
  font-size: 1.1rem !important;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: inline-block;

  @media screen and (min-width: 700px) {
    ${styles.PostParagraph};
    text-indent: 0rem !important;
    font-size: 0.9rem !important;
    margin-left: ${styles.HomeIndent};
    margin-right: ${styles.HomeIndent};
  }
`;

const ArrowSpan = styled.span`
  font-size: ${styles.HomeFontSize};
  font-family: ${styles.HomeFontFamily};
`;

function Arrow(props) {
  const { style } = props;
  let className = props.type === "next" ? "nextArrow" : "prevArrow";
  className += " arrow";
  const char = props.type === "next" ? ">" : "<";
  return (
    <ArrowSpan
      className={className}
      onClick={props.onClick}
      style={{
        ...style,
        position: "absolute",
        // background: "red",
        zIndex: "100",
        padding: "10px",
      }}
    >
      <span>{char}</span>
    </ArrowSpan>
  );
}

const settings = {
  arrows: true,
  nextArrow: <Arrow type="next" />,
  prevArrow: <Arrow type="prev" />,
  dots: false,
  infinite: true,
  speed: 600,
  slidesToShow: 1,
  slidesToScroll: 1,
  focusOnSelect: true,
  variableWidth: false,
  adaptiveHeight: false,
  // centerMode: true,
};

const PostSlider = ({ images }) => {
  return (
    <OuterSliderContainer>
      <InnerSliderContainer>
        <Slider {...settings}>
          {images.map((item, index) =>
            item.caption != null ? (
              <SliderImageContainer key={index}>
                <SliderImage key={index} src={item.image} />
                <SliderCaption>{item.caption}</SliderCaption>
              </SliderImageContainer>
            ) : (
              <SliderImageContainer key={index}>
                <SliderImage key={index} src={item.image} />
              </SliderImageContainer>
            )
          )}
        </Slider>
      </InnerSliderContainer>
    </OuterSliderContainer>
  );
};

PostSlider.propTypes = {
  images: PropTypes.array,
  image: PropTypes.string,
};

export default PostSlider;
