import { ExhibitionsList } from "./ExhibitionsList";
import {
  ExhibitionsLink,
  HomeBulletList,
  HomeBulletItem,
  HomeBulletSymbol,
  HomeBulletItemText,
} from "../Home.style";

export const ExhibitionsContent = (
  <HomeBulletList>
    {ExhibitionsList.map((item, index) => (
      <HomeBulletItem>
        <HomeBulletSymbol>•</HomeBulletSymbol>
        <HomeBulletItemText>
          <ExhibitionsLink
            key={index}
            href={item.href}
            to={item.to}
            target={item.target}
          >
            {item.title}
          </ExhibitionsLink>
        </HomeBulletItemText>
      </HomeBulletItem>
    ))}
  </HomeBulletList>
);