export const ExhibitionsList = [
  {
    title: [
      <em>
        Dwelling on the Platform: Housing Access and Equity in the Digital
        Society
      </em>,
      ", Jacqui Alexander. MADA Gallery, Melbourne. 2023.",
    ],
    href: "/exhibitions/dwelling-on-the-platform",
    to: "/exhibitions/dwelling-on-the-platform",
    target: "",
  },
  {
    title: [
      <em>This Time Tomorrow</em>,
      ", Jacqui Alexander and Ben Sheridan (POST) with Monash Art Projects (MAP), ‘It’s All Yours’, Victoria and Albert Museum, 2014",
    ],
    href: "/exhibitions/this-time-tomorrow",
    to: "/exhibitions/this-time-tomorrow",
    target: "",
  },
  {
    title: [
      <em>Tenancy ≠ Title</em>,
      " in ‘A New Suburban Ambition’, Melbourne Design Week, 2021",
    ],
    href: "https://designweek.melbourne/events/a-new-suburban-ambition/",
    to: "/",
    target: "_blank",
  },
  {
    title: [
      <em>Tenancy ≠ Title</em>,
      " in ‘AA Prize for Unbuilt Work’ exhibition, Melbourne Design Week, 2021",
    ],
    href: "https://designweek.melbourne/events/aa-prize-for-unbuilt-work/",
    to: "/",
    target: "_blank",
  },
  {
    title: [
      <em>Supershared</em>,
      " with SIBLING Architecture; in ‘Occupied’, RMIT Design Hub, 2016",
    ],
    href: "https://designhub.rmit.edu.au/exhibitions-programs/occupied/",
    to: "/",
    target: "_blank",
  },
  {
    title: [
      <em>POST Magazine</em>,
      ", ‘Are You Reading Me?’ at  Melbourne Now, NGV Australia, 2014",
    ],
    href: "https://www.ngv.vic.gov.au/melbournenow/projects/537.html",
    to: "/",
    target: "_blank",
  },
  {
    title: [
      <em>POST Magazine</em>,
      ", ‘Archizines’, 2013-2015: RMIT Design Hub",
    ],
    href: "https://designhub.rmit.edu.au/exhibitions-programs/archizines/",
    to: "/",
    target: "_blank",
  },
  {
    title: [<em>POST Magazine</em>, ", ‘Public Offer’, RMIT Design Hub, 2013"],
    href: "https://designhub.rmit.edu.au/exhibitions-programs/public-offer-ways-to-share-design/",
    to: "/",
    target: "_blank",
  },
  {
    title: [<em>POST Magazine</em>, ", ‘Archizines World Tour’, 2011-2015"],
    href: "http://archis.org/volume/archizines-the-magazine-as-an-art-form/",
    to: "/",
    target: "_blank",
  },
];
