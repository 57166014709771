export const LecturesList = [
  {
    title: ["ABC Radio National: ", "Disruptive Domesticity", ", 2021"],
    href: "https://www.abc.net.au/radionational/programs/blueprintforliving/disruptive-domesticity:-the-spatial-dynamics-of-the-sharing-eco/13301190",
    to: "/",
    target: "_blank",
  },
  {
    title: [
      "ABC Radio National: ",
      "Urbanism in the Digital Age",
      " -- Jacqui Alexander in conversation with Dan Hill, 2021",
    ],
    href: "https://www.abc.net.au/radionational/programs/blueprintforliving/dan-hill-speculative-design-urbanism-digital-age/13333992",
    to: "/",
    target: "_blank",
  },
  {
    title: ["Design Speaks: ", "Housing Futures", ", 2018"],
    href: "https://designspeaks.com.au/events/2018/07/27/housing-futures",
    to: "/",
    target: "_blank",
  },

  {
    title: ["ACCA ", "Future Forum", " with Jacqui Alexander, 2017"],
    href: "https://soundcloud.com/acca_melbourne/future-forum-architecture-with-jacqui-alexander",
    to: "/",
    target: "_blank",
  },
];
