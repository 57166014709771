import React from "react";
import styled from "styled-components";
import styles from "../styles.js";

const LinkColours = ["#609d00", "#2837ff", "#ff1c7e", "#ff7700", "#01bcb9"];

export const RandomColour =
  LinkColours[Math.floor(Math.random() * LinkColours.length)];

const AlexanderSheridanLink = styled.a`
  font-family: ${styles.AlexanderSheridanFont};
  color: ${RandomColour};
  text-decoration: underline;
`;
const AlexanderSheridanHeader = () => {
  return (
    <AlexanderSheridanLink href="/">
      Alexander & Sheridan Architecture
    </AlexanderSheridanLink>
  );
};

export default AlexanderSheridanHeader;
