import React from "react";
import {
  useDocumentTitle,
  ProjectAlexanderSheridanHeader,
  MainContainer,
  PostParagraph,
  FirstPostParagraph,
  HomeParagraph,
  PostParagraphContainer,
  PostTitleContainer,
  ProjectLinkContainer,
  NextProjectContainer,
  NextProjectLink,
  NextProjectSymbol,
} from "../project-page/ProjectPageElements.js";
import Footer from "../../components/Footer";
import PostSlider from "../../components/post/PostSlider.js";

import image1 from "../../images/projects/yarraville/1_yarraville-interior.jpg";
import image2 from "../../images/projects/yarraville/2_loft.jpg";
import image3 from "../../images/projects/yarraville/3_int-kitchen-east.jpg";
import image4 from "../../images/projects/yarraville/4_yarraville-plan-oblique.jpg";

import { ProjectsList } from "../home/projects/ProjectsList.js";

const ProjectIndex = ProjectsList.findIndex(
  (p) => p.href === "/projects/yarraville-loft"
);

const PostTitle = ProjectsList[ProjectIndex].title;

const images = [
  {
    image: image1,
    caption: "Yarraville Loft extension: looking towards the garden",
  },
  {
    image: image2,
    caption: "View from mezzanine: maximising light and volume",
  },
  {
    image: image3,
    caption: "Kitchen and dining room",
  },
  {
    image: image4,
    caption: ["Plan Oblique. ", <em>Image Credits: George Mellos</em>],
  },
];

const Yarraville = () => {
  useDocumentTitle(PostTitle);
  return (
    <MainContainer>
      <HomeParagraph>
        <ProjectAlexanderSheridanHeader />{" "}
        <PostTitleContainer>{PostTitle}</PostTitleContainer> in Melbourne’s
        Inner West is a hard working alterations and additions project which
        negotiates the constraints of a tight site and budget.
      </HomeParagraph>
      <PostSlider images={images}></PostSlider>
      <PostParagraphContainer>
        <FirstPostParagraph>
          The architectural brief required the demolition of a leaky kitchen and
          bathroom lean-to, and replacement with a new dining space, kitchen,
          bathroom, and a bedroom.
        </FirstPostParagraph>
        <PostParagraph>
          In order to preserve garden space and avoid encroaching on the
          neighbours’ amenity, a double height addition was proposed, with
          living spaces below and a loft-like bedroom and ensuite above. To
          minimise costs associated with plumbing, wet areas are stacked,
          forming a knuckle space and threshold between the existing house and
          the new addition. A courtyard is introduced to provide light to the
          existing living space. Spaciousness is enhanced through visual
          connections between the mezzanine and ground floor.
        </PostParagraph>
        <PostParagraph>
          Cheap and ubiquitous materials such as oriented strand board, recycled
          bricks and timber floorboards, and FC sheet are employed in
          unconventional ways to enhance the character of interior spaces, whist
          the powder-coated perforated steel staircase and shutters provide
          permeability and capture shadows, enlivening the space.
        </PostParagraph>
      </PostParagraphContainer>

      <ProjectLinkContainer>
        <HomeParagraph>
          <NextProjectContainer>
            <NextProjectLink href={ProjectsList[ProjectIndex + 1].href}>
              {ProjectsList[ProjectIndex + 1].title}
            </NextProjectLink>
            <NextProjectSymbol />
          </NextProjectContainer>
        </HomeParagraph>
      </ProjectLinkContainer>

      <Footer />
    </MainContainer>
  );
};

export default Yarraville;
