import React from "react";
import {
  useDocumentTitle,
  ProjectAlexanderSheridanHeader,
  MainContainer,
  PostParagraph,
  FirstPostParagraph,
  HomeParagraph,
  PostParagraphContainer,
  PostTitleContainer,
  ProjectLinkContainer,
  NextProjectContainer,
  NextProjectLink,
  NextProjectSymbol,
} from "../project-page/ProjectPageElements.js";
import Footer from "../../components/Footer";

import PostSlider from "../../components/post/PostSlider.js";

import image1 from "../../images/projects/euroa/1_euroa-view.jpg";
import image2 from "../../images/projects/euroa/2_euroa-view.jpg";
import image3 from "../../images/projects/euroa/3_euroa-parti-plan.jpg";
import image4 from "../../images/projects/euroa/4_euroa-model.jpg";
import image5 from "../../images/projects/euroa/5_euroa-flattened.jpg";

import { ProjectsList } from "../home/projects/ProjectsList.js";

const ProjectIndex = ProjectsList.findIndex(
  (p) => p.href === "/projects/euroa-house"
);

const PostTitle = ProjectsList[ProjectIndex].title;

const images = [
  {
    image: image1,
    caption:
      "Euroa House on Taungurung Country nestled within its rocky, granite-strewn site.",
  },
  {
    image: image2,
    caption:
      "Euroa House overlooking the valley to the west. View shows shaded walkways connecting pavilions, from which to enjoy the landscape.",
  },
  {
    image: image3,
    caption:
      "Euroa House Parti plan: three pavilions are punctuated by covered outdoor rooms, which provide shade from the harsh western sun and allow protected enjoyment of the outdoors.",
  },
  {
    image: image4,
    caption:
      "Model view: showing landscaped areas to the north of the house and the three pavilions cascading down the site.",
  },
  {
    image: image5,
    caption: [
      "Plan Oblique showing interiors. ",
      <em>Image credits: George Mellos</em>,
    ],
  },
];

const EuroaHouse = () => {
  useDocumentTitle(PostTitle);
  return (
    <MainContainer>
      <HomeParagraph>
        <ProjectAlexanderSheridanHeader />{" "}
        <PostTitleContainer>{PostTitle}</PostTitleContainer> is designed to
        enable aging in place for an empty-nester couple with a growing extended
        family.
      </HomeParagraph>
      <PostSlider images={images}></PostSlider>
      <PostParagraphContainer>
        <FirstPostParagraph>
          The house is conceptualised as three discrete pavilions — one for
          sleeping, one for living and one for guests. They are interconnected
          by covered verandah spaces, directing views within the landscape. The
          house carefully negotiates a number of complex and contradictory
          social and environmental conditions, for example – the requirement for
          single-level living despite a rocky, sloping site, and the desire to
          capture views to the west but maintain excellent thermal performance
          within the harsh climate of North Western Victoria.
        </FirstPostParagraph>
        <PostParagraph>
          By adopting a long, thin plan, and splitting the house into three
          self-contained zones, the design achieves thermal mass and effective
          cross ventilation, whist minimizing internal stairs and affording
          privacy for residents and guests. ‘Hit-and-miss’ brick walls are
          employed to screen the harsh western light, whilst affording views out
          to the horizon, creating textured light and shadow.
        </PostParagraph>
      </PostParagraphContainer>

      <ProjectLinkContainer>
        <HomeParagraph>
          <NextProjectContainer>
            <NextProjectLink href={ProjectsList[ProjectIndex + 1].href}>
              {ProjectsList[ProjectIndex + 1].title}
            </NextProjectLink>
            <NextProjectSymbol />
          </NextProjectContainer>
        </HomeParagraph>
      </ProjectLinkContainer>

      <Footer />
    </MainContainer>
  );
};

export default EuroaHouse;
