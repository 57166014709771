import React from "react";
import {
  useDocumentTitle,
  ProjectAlexanderSheridanHeader,
  MainContainer,
  PostParagraph,
  FirstPostParagraph,
  HomeParagraph,
  PostParagraphContainer,
  PostTitleContainer,
  ProjectLinkContainer,
  NextProjectContainer,
  NextProjectLink,
  NextProjectSymbol,
} from "../project-page/ProjectPageElements.js";
import Footer from "../../components/Footer";
import PostSlider from "../../components/post/PostSlider.js";

import image1 from "../../images/projects/fitzroy/1_gore-model.jpg";
import image2 from "../../images/projects/fitzroy/2_gore-bomboli.jpg";

import { ProjectsList } from "../home/projects/ProjectsList.js";

const ProjectIndex = ProjectsList.findIndex(
  (p) => p.href === "/projects/fitzroy-extension"
);

const PostTitle = ProjectsList[ProjectIndex].title;

const images = [
  {
    image: image1,
    caption: "Fitzroy Extension concept model",
  },
  {
    image: image2,
    caption: ["Plan Oblique. ", <em>Image Credits: George Mellos</em>],
  },
];

const Fitzroy = () => {
  useDocumentTitle(PostTitle);
  return (
    <MainContainer>
      <HomeParagraph>
        <ProjectAlexanderSheridanHeader />{" "}
        <PostTitleContainer>{PostTitle}</PostTitleContainer> tackles the common
        problem of adapting the Victorian terrace typology to suit the lifestyle
        of the 21st Century household, maximising natural light, ventilation and
        affording a stronger connection to the garden.
      </HomeParagraph>
      <PostSlider images={images}></PostSlider>
      <PostParagraphContainer>
        <FirstPostParagraph>
          The extension is built to the boundary on both sides. Compensating for
          the narrowness of the block, the design adopts a hipped roof,
          expressed internally as a folded sculptural ceiling that achieves a
          sense of spaciousness in the vertical dimension whilst respecting the
          neighbours to the south.
        </FirstPostParagraph>
        <PostParagraph>
          An operable skylight at the apex purges hot air but also provides
          spatial drama moving through the house towards the garden. A series of
          terraces that define the laundry, kitchen and lounge room negotiate
          the slope of the site, and continue outdoors in the form of a patio
          connecting house and garden.
        </PostParagraph>
      </PostParagraphContainer>
      <Footer />
    </MainContainer>
  );
};

export default Fitzroy;
