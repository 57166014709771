import React, { useState, useRef, useEffect } from "react";
import "./Collapsible.css";
import PropTypes from "prop-types";
import styled from "styled-components";

const ContactContainer = styled.div`
  display: inline;
  white-space: normal;
`;

const ContactToggle = ({ button, hiddenContent, linkColour }) => {
  const [contactDisplay, setContactDisplay] = useState("none");
  const [originalDisplay, setOriginalDisplay] = useState("inline");

  const openContact = () => {
    setContactDisplay("inline");
    setOriginalDisplay("none");
  };

  return (
    <React.Fragment>
      <ContactContainer
        className={`contact ${contactDisplay}`}
        onClick={(event) => {
          console.log(`🮰 Collapsible.js --- clicked`);
          openContact();
        }}
      >
        <span className="contact__title">
          <span
            style={{
              color: `${linkColour}`,
              textDecorationColor: `${linkColour}`,
              textDecoration: `${"underline"}`,
              display: `${originalDisplay}`,
            }}
          >
            {button}
          </span>
          <span
            className="contact__section"
            style={{
              display: `${contactDisplay}`,
            }}
          >
            {hiddenContent}
          </span>
        </span>
      </ContactContainer>
    </React.Fragment>
  );
};

ContactToggle.propTypes = {
  button: PropTypes.string,
  hiddenContent: PropTypes.element,
  linkColour: PropTypes.string,
};

export default ContactToggle;
