import React from "react";
import {
  useDocumentTitle,
  ProjectAlexanderSheridanHeader,
  MainContainer,
  PostParagraph,
  FirstPostParagraph,
  HomeParagraph,
  PostParagraphContainer,
  PostTitleContainer,
  ProjectLinkContainer,
  NextProjectContainer,
  NextProjectLink,
  NextProjectSymbol,
} from "../project-page/ProjectPageElements.js";
import Footer from "../../components/Footer";
import PostSlider from "../../components/post/PostSlider.js";

import image1 from "../../images/projects/tenancytitle/1_HanoverSt-elevation-stylised.jpg";
import image2 from "../../images/projects/tenancytitle/2_Section-BB-blow-up-coloured.jpg";
import image3 from "../../images/projects/tenancytitle/3_courtyard-ed.jpg";
import image4 from "../../images/projects/tenancytitle/4_Communal-Space.jpg";
import image5 from "../../images/projects/tenancytitle/5_apartment-enfilade.jpg";
import image6 from "../../images/projects/tenancytitle/6-west-apt.gif";
import image7 from "../../images/projects/tenancytitle/7_exterior_entourage.jpg";
import image8 from "../../images/projects/tenancytitle/8_TT_Lineweights.jpg";

import { ProjectsList } from "../home/projects/ProjectsList.js";

const ProjectIndex = ProjectsList.findIndex(
  (p) => p.href === "/projects/tenancy-title"
);

const PostTitle = ProjectsList[ProjectIndex].title;

const images = [
  {
    image: image1,
    caption:
      "Street Elevation, showing the implementation of a new arcade space that invites members of the community into the common garden spaces at ground floor level. The pink brick facade responds to the surrounding industrial fabric, and operable timber window screens enliven the facade by registering the living patterns of residents.",
  },
  {
    image: image2,
    caption:
      "Section showing adapted apartments with cassette flooring removed to create two storey configurations.",
  },
  {
    image: image3,
    caption:
      "Centralised courtyards with individual kitchen serveries that open up to the common gardens for event, and can be aggregated to collectivise domestic labour and childcare.",
  },
  {
    image: image4,
    caption:
      "View of the communal kitchen and living space, demonstrating how rooms can be aggregated to form larger collective spaces, in this case, for a not-for-profit housing provider tenant at ground floor.",
  },
  {
    image: image5,
    caption:
      "Two consecutive apartments joined together, with flooring cassette removed to form a mezzanine.",
  },
  {
    image: image6,
    caption:
      "West facing apartment with a loose-fit design that can be subdivided in a variety of ways for different household configurations and working from home conditions.",
  },
  {
    image: image7,
    caption:
      "The title boundary is expressed as a continuous fence that wraps around the building, responding to the neighbourhood character and funneling traffic through the arcade, activating the tenancies and gardens within.",
  },
  {
    image: image8,
    caption:
      "Axonometric diagram illustrating the reconfigurable CLT construction system, including centralised service pods that can be adapted with staircases, and removable flooring cassettes.",
  },
];

const TenancyTitle = () => {
  useDocumentTitle(PostTitle);
  return (
    <MainContainer>
      <HomeParagraph>
        <ProjectAlexanderSheridanHeader />{" "}
        <PostTitleContainer>{PostTitle}</PostTitleContainer> This speculative
        housing project situated in Fitzroy, Melbourne, takes the positive
        aspects of contemporary sharing and on-demand cultures to inform a new
        model for collective living.
      </HomeParagraph>
      <PostSlider images={images}></PostSlider>
      <PostParagraphContainer>
        <FirstPostParagraph>
          The project identifies one of the innovations of Sharing Economy
          platforms like Airbnb as the capacity to rent space more flexibly and
          incrementally. In other words, both the amount of space, and the
          amount of time that we can rent it for, have shrunk. This means it is
          now possible to rent a home for an hour, day or month. Equally, it is
          now possible to subdivide and rent parts of a home without adhering to
          traditional legal mechanisms like strata title. It is argued here,
          that this decoupling of tenancy and title presents new opportunities
          to rethink rental housing more broadly.
        </FirstPostParagraph>

        <PostParagraph>
          Starting with this premise, <em>Tenancy ≠ Title</em> facilitates the
          flexible occupation of more or less rooms, ‘on-demand,’ through a
          series of architectural and regulatory devices. Here, the apartment
          block is reimagined as a continuous house all on the one land title –
          similar to the mansion flats of the early 20th century – and this has
          several benefits. Firstly, it ensures that the property has no strata
          division, so dwellings are not able to be sold as commodity and asset,
          keeping the cost of the land down. In addition to these affordability
          measures, maintaining the apartment block on a single title can
          elevate design and construction quality, as the whole property remains
          in the hands of a single entity over its lifespan, so there is more
          incentive for developers to invest in good quality, durable materials.
        </PostParagraph>

        <PostParagraph>
          Secondly, no strata division means that tenancies can be flexibly
          negotiated, enabling vertical and horizontal occupation of consecutive
          rooms to form a range of different dwelling types. Architectural
          strategies such as the ‘enfilade’ allow residents to expand into
          adjacent spaces with ease. Similarly, Cross Laminated Timber (CLT)
          construction techniques enable the conversion of single-level
          dwellings into two-storey types, without the need for major structural
          modifications.
        </PostParagraph>

        <PostParagraph>
          The centralised courtyards at the ground floor are activated by way of
          a covered arcade space running north south, providing community access
          to small-scale tenancies. Ground floor apartments are designed so that
          kitchens have a direct relationship to the outdoors, providing the
          scope to collectivise cooking and domestic duties like childcare.
          Other shared amenities include a rooftop space, bike and car share, as
          well as assets like solar energy. Ultimately, this proposal
          demonstrates ways that the logic of platform technology, which has
          been used to amplify the commodification of the built environment,
          can, with creativity, be used to reverse those impacts. Through
          rethinking tenure, type and regulatory barriers, this design research
          project advocates for long-term “ring fencing” of affordable dwellings
          in the inner city, providing long-life, loose fit, flexible housing
          for generation rent.
        </PostParagraph>
      </PostParagraphContainer>

      <ProjectLinkContainer>
        <HomeParagraph>
          <NextProjectContainer>
            <NextProjectLink href={ProjectsList[ProjectIndex + 1].href}>
              {ProjectsList[ProjectIndex + 1].title}
            </NextProjectLink>
            <NextProjectSymbol />
          </NextProjectContainer>
        </HomeParagraph>
      </ProjectLinkContainer>

      <Footer />
    </MainContainer>
  );
};

export default TenancyTitle;
