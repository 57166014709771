import React from "react";
import {
  useDocumentTitle,
  ProjectAlexanderSheridanHeader,
  MainContainer,
  PostParagraph,
  FirstPostParagraph,
  HomeParagraph,
  PostParagraphContainer,
  PostTitleContainer,
  ProjectLinkContainer,
  NextProjectContainer,
  NextProjectLink,
  NextProjectSymbol,
} from "../project-page/ProjectPageElements.js";
import Footer from "../../components/Footer";
import PostSlider from "../../components/post/PostSlider.js";

import image1 from "../../images/projects/supershared/1_Photo-Tobias-Titz.jpg";
import image2 from "../../images/projects/supershared/2_SiblingArchitecture_SuperShared-02.jpg";
import image3 from "../../images/projects/supershared/5_Supershared-Lineweights-2.jpg";
import image4 from "../../images/projects/supershared/3_Photo-Tobias-Titz.jpg";
import image5 from "../../images/projects/supershared/4_Photo-Tobias-Titz.jpg";

import { ProjectsList } from "../home/projects/ProjectsList.js";

const ProjectIndex = ProjectsList.findIndex(
  (p) => p.href === "/projects/supershared"
);

const PostTitle = ProjectsList[ProjectIndex].title;

const images = [
  {
    image: image1,
    caption: [
      "Supershared Loft, with Sibling Architecture. ",
      <em>Image credits: Tobias Titz</em>,
    ],
  },
  {
    image: image2,
    caption: [
      "Supershared Loft interior, with Sibling Architecture. ",
      <em>Image credits: Sibling Architecture</em>,
    ],
  },
  {
    image: image4,
    caption: [
      "Occupied Exhibition, looking towards Supershared. ",
      <em>Image Credits: Tobias Titz</em>,
    ],
  },
  {
    image: image5,
    caption: [
      "View from Supershared looking out over exhibition. ",
      <em>Image Credits: Tobias Titz</em>,
    ],
  },
  {
    image: image3,
    caption: [
      "Supershared Loft in the context of the exhibition. ",
      <em>Image credits: George Mellos</em>,
    ],
  },
];

const Supershared = () => {
  useDocumentTitle(PostTitle);
  return (
    <MainContainer>
      <HomeParagraph>
        <ProjectAlexanderSheridanHeader />{" "}
        <PostTitleContainer>{PostTitle}</PostTitleContainer> was an interactive
        installation developed as part of Occupied – an exhibition at the RMIT
        Design Hub curated by Fleur Watson, David Neustein and Grace Mortlock,
        showcasing creative proposals for re-occupying the contemporary city in
        light of dwindling resources, population growth and rising inequality.
      </HomeParagraph>
      <PostSlider images={images}></PostSlider>
      <PostParagraphContainer>
        <FirstPostParagraph>
          Designed in collaboration with Sibling Architects, Supershared took
          the form of a 10m2 loft space, hovering over the exhibition. The loft
          was listed on a number of different space-sharing platforms
          simultaneously, and willing guests were invited to book time in the
          space for work, leisure and to host events, introducing an
          unpredictable and theatrical element to an otherwise static show.
          Through the construction and occupation of the loft, a complex
          ecosystem of actors and factors that surround the increasingly
          professionalised space-sharing economy was exposed, along with the
          creative potentials of the technology when it is pushed to its limits.
        </FirstPostParagraph>
        <PostParagraph>
          By maximising the number and diversity of platforms on which the loft
          was listed, Supershared explored the potential to create
          ‘interference’ within the space, through overlapping bookings that
          varied in duration. Through digital cross-programming, Supershared
          created social interactions between parties who booked the space at
          the same time but for different purposes, constructing surprise
          encounters, and a scenario wherein participants were encouraged to
          negotiate and genuinely share – something that appears to have been
          lost in the transactional marketplace-based ‘sharing economy’. By the
          end of the exhibition, Supershared had been used as a venue to host
          exhibitions, dance classes, poetry readings, movie nights, meetings,
          textile production, panel discussions, and even date nights.
          Ultimately, Supershared demonstrates how digital platforms can be
          strategically adapted beyond a market-interface as an architectural
          tool to intensify and program space in new ways, and suggests the
          possibility of applying these tools to broader urban settings, to
          activate and enliven underutilised parts of the city.
        </PostParagraph>
      </PostParagraphContainer>

      <ProjectLinkContainer>
        <HomeParagraph>
          <NextProjectContainer>
            <NextProjectLink href={ProjectsList[ProjectIndex + 1].href}>
              {ProjectsList[ProjectIndex + 1].title}
            </NextProjectLink>
            <NextProjectSymbol />
          </NextProjectContainer>
        </HomeParagraph>
      </ProjectLinkContainer>

      <Footer />
    </MainContainer>
  );
};

export default Supershared;
