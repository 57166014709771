import React, { useState, useRef, useEffect } from "react";
import "./Collapsible.css";
import PropTypes from "prop-types";
import styled from "styled-components";

const ContactContainer = styled.div`
  display: inline;
  white-space: normal;
`;

const ContactToggle = ({ button, hiddenContent, linkColour }) => {
  const [active, setActive] = useState(() => {
    return false;
  });
  const [contactDisplay, setContactDisplay] = useState("none");
  const [originalDisplay, setOriginalDisplay] = useState("inline");

  const handleContactClick = (event) => {
    setActive((prevState) => !prevState);
  };

  useEffect(() => {
    active ? openContact() : closeContact();
  }, [active]);

  const openContact = () => {
    setContactDisplay("inline");
    setOriginalDisplay("none");
  };

  const closeContact = () => {
    setContactDisplay("none");
    setOriginalDisplay("inline");
  };

  return (
    <React.Fragment>
      <ContactContainer
        className={`contact-toggle ${active}`}
        onClick={(event) => {
          handleContactClick(event);
        }}
      >
        <span className="contact-toggle__title">
          <span
            style={{
              color: `${linkColour}`,
              textDecorationColor: `${linkColour}`,
              textDecoration: `${"underline"}`,
              display: `${originalDisplay}`,
            }}
          >
            {button}
          </span>
          <span
            className="contact-toggle__section"
            style={{
              display: `${contactDisplay}`,
              textDecoration: "none",
            }}
          >
            {hiddenContent}
          </span>
        </span>
      </ContactContainer>
    </React.Fragment>
  );
};

ContactToggle.propTypes = {
  button: PropTypes.string,
  hiddenContent: PropTypes.element,
  linkColour: PropTypes.string,
};

export default ContactToggle;
