import { ProjectsList } from "./ProjectsList";
import {
  ProjectsLink,
  HomeBulletList,
  HomeBulletItem,
  HomeBulletSymbol,
  HomeBulletItemText,
} from "../Home.style";

export const ProjectsContent = (
  <HomeBulletList>
    {ProjectsList.map((item, index) => (
      <HomeBulletItem>
        <HomeBulletSymbol>•</HomeBulletSymbol>
        <HomeBulletItemText>
          <ProjectsLink
            key={index}
            href={item.href}
            to={item.to}
            target={item.target}
          >
            {item.title}
          </ProjectsLink>
        </HomeBulletItemText>
      </HomeBulletItem>
    ))}
  </HomeBulletList>
);
