import styled from "styled-components";
import styles from "../../styles.js";

function shuffle(array) {
  var currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

const LinkColours = ["#609d00", "#2837ff", "#ff1c7e", "#ff7700", "#01bcb9"];
const ShuffledLinkColours = shuffle(LinkColours);

export const projectsColour = ShuffledLinkColours[0];
export const exhibitionsColour = ShuffledLinkColours[1];
export const publicationsColour = ShuffledLinkColours[2];
export const lecturesColour = ShuffledLinkColours[3];
export const awardsColour = ShuffledLinkColours[4];

export const MainContainer = styled.div`
  min-height: 99vh;
  display: flex;
  flex-direction: column;
`;

export const HomeBody = styled.body`
  margin: 0;
  padding: 0;
`;

export const HomeParagraph = styled.p`
  ${styles.HomeParagraph}
  text-indent: ${styles.HomeIndentM};
  @media screen and (min-width: 700px) {
    ${styles.HomeParagraph}
    text-indent: ${styles.HomeIndent};
  }
`;

export const ProjectsLink = styled.a`
  text-decoration: underline;
  text-decoration-color: ${projectsColour};
  color: ${projectsColour};
`;

export const ExhibitionsLink = styled.a`
  text-decoration: underline;
  text-decoration-color: ${exhibitionsColour};
  color: ${exhibitionsColour};
`;
export const PublicationsLink = styled.a`
  text-decoration: underline;
  text-decoration-color: ${publicationsColour};
  color: ${publicationsColour};
`;
export const LecturesLink = styled.a`
  text-decoration: underline;
  text-decoration-color: ${lecturesColour};
  color: ${lecturesColour};
`;
export const AwardsLink = styled.a`
  text-decoration: underline;
  text-decoration-color: ${awardsColour};
  color: ${awardsColour};
`;

export const FirstParagraph = styled.p`
  text-indent: 0rem;
  margin: inherit;
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  max-width: inherit;
  word-spacing: inherit;
`;

export const HomeMainLink = styled.a`
  ${styles.HomeLink};
  text-decoration: underline;
  text-decoration-color: ${styles.LinkBlue};
  color: ${styles.LinkBlue};
  display: "inline-block !important";
`;

export const HomeBulletList = styled.ul`
  margin-left: ${styles.HomeIndentM};
  margin-right: 0.5rem;

  @media screen and (min-width: 700px) {
    margin-right: ${styles.HomeIndent};
    margin-left: ${styles.HomeIndent};
  }
`;

export const HomeBulletItem = styled.li`
  display: list-item;
  list-style-type: "";
`;

export const HomeBulletSymbol = styled.span`
  user-select: none;
  display: inline-block;
  position: relative;
  top: -0.5rem;
  width: 2rem;
  padding-right: 0.5rem;
  font-size: 2rem;
  line-height: 0rem;
`;

export const HomeBulletItemText = styled.span`
  font-size: ${styles.HomeFontSize};
`;

export const PopUpImage = styled.div`
  img {
    max-width: 35rem;
    max-height: 25rem;
    border: 2.5px solid black;
    @media screen and (min-width: 700px) {
      border: 5px solid black;
    }
  }
  img a {
    outline: black;
  }
  line-height: 0;
  display: none;
  position: absolute;
  z-index: 100;

  transform-origin: center right;
  transform: translate(30%, -50%);

  @media screen and (min-width: 700px) {
    transform-origin: center;
    transform: translate(70%, -50%);
  }

  ${PublicationsLink}:hover & {
    display: block;
  }
  ${AwardsLink}:hover & {
    display: block;
  }
`;

export const CollapsibleOuter = styled.div`
  display: inline;
`;

export const ContactLine = styled.span`
  display: block;
  text-decoration: underline;
`;

export const ContactPlain = styled.span`
  color: black;
  text-decoration-color: black;
  text-decoration: none;
`;

export const ContactReverse = styled.span`
  unicode-bidi: bidi-override;
  direction: rtl;
`;

export const Logo1 = styled.img`
  height: 2.7rem;
  //position: relative;
`;

export const Logo2 = styled.img`
  height: 2.3rem;
  margin-left: 1.5rem;
`;

export const LogoInnerContainer = styled.div`
  display: inline-flex;
  justify-content: flex-start;
`;

export const LogoContainer = styled.div`
  position: relative;
  top: 0.6rem;
  margin-bottom: auto;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  height: 2rem;
  width: auto;
`;

export const Wrap = styled.span``;

export const WrapSpan = styled.span`
  display: inline-block;
  text-decoration: underline;
`;
