import React from "react";
import {
  useDocumentTitle,
  ProjectAlexanderSheridanHeader,
  MainContainer,
  PostParagraph,
  FirstPostParagraph,
  HomeParagraph,
  PostParagraphContainer,
  PostTitleContainer,
} from "../project-page/ProjectPageElements.js";
import Footer from "../../components/Footer.js";
import PostSlider from "../../components/post/PostSlider.js";

import image1 from "../../images/exhibitions/dwelling/1_Dwellingontheplatform.jpg";
import image2 from "../../images/exhibitions/dwelling/2_Dwellingontheplatform.jpg";
import image3 from "../../images/exhibitions/dwelling/3_Dwellingontheplatform.jpg";
import image4 from "../../images/exhibitions/dwelling/4_Dwellingontheplatform.jpg";
import image5 from "../../images/exhibitions/dwelling/5_Dwellingontheplatform.jpg";
import image6 from "../../images/exhibitions/dwelling/6_Dwellingontheplatform.jpg";
import image7 from "../../images/exhibitions/dwelling/7_Dwellingontheplatform.jpg";
import image8 from "../../images/exhibitions/dwelling/8_Dwellingontheplatform.jpg";
import image9 from "../../images/exhibitions/dwelling/9_Dwellingontheplatform.png";

const PostTitle = "Dwelling on the Platform";

const images = [
  {
    image: image1,
    caption: <em>Image Credits: Tobias Titz</em>,
  },
  {
    image: image2,
    caption: <em>Image Credits: Tobias Titz</em>,
  },
  {
    image: image3,
    caption: <em>Image Credits: Tobias Titz</em>,
  },
  {
    image: image4,
    caption: <em>Image Credits: Tobias Titz</em>,
  },
  {
    image: image5,
    caption: <em>Image Credits: Tobias Titz</em>,
  },
  {
    image: image6,
    caption: <em>Image Credits: Tobias Titz</em>,
  },
  {
    image: image7,
    caption: <em>Image Credits: Tobias Titz</em>,
  },
  {
    image: image8,
    caption: <em>Image Credits: Michael Pham</em>,
  },
  {
    image: image9,
    caption: <em>Image Credits: Alexander & Sheridan Architecture</em>,
  },
];

const Dwelling = () => {
  useDocumentTitle(PostTitle);
  return (
    <MainContainer>
      <HomeParagraph>
        <ProjectAlexanderSheridanHeader />{" "}
        <PostTitleContainer>
          {PostTitle}: Housing Access and Equity in the Digital Society
        </PostTitleContainer>{" "}
        was an exhibition at the MADA Gallery in Melbourne, Australia showcasing
        a series of creative works that explore the conditions, opportunities,
        and contingencies of architecture in the era of the platform.
      </HomeParagraph>
      <PostSlider images={images}></PostSlider>
      <PostParagraphContainer>
        <FirstPostParagraph>
          <em>Dwelling on the Platform</em> was designed in collaboration with
          Anthony Clarke of BLOXAS whose exhibition{" "}
          <em>
            Architecture of Care: Using an Auto - Ethnographic Design Approach
            to Rearticulate Practice
          </em>{" "}
          shared the space.
        </FirstPostParagraph>
        <PostParagraph>
          Platform technologies and economies are rapidly and surreptitiously
          transforming our society. They are changing the way we live, how and
          where we work, what we want or need to own, and how much space we
          require — with significant implications for the built environment.
          Through mobile GPS systems embedded in our smartphones, platforms have
          developed new ways to triangulate time, space and money, reshaping
          behaviours at a global scale, with private property at the core of
          their business model. Today, data has eclipsed oil as the world’s most
          valuable commodity, signalling a new urban economy and ecology.
          Initially, this scenario was lauded as a potential panacea for the
          environmental crisis, with democratising potentials, yet — as this
          exegesis will highlight — the impact of big data on urban space has
          been far from liberating.
        </PostParagraph>
        <PostParagraph>
          Through a series of speculative creative works,{" "}
          <em>
            Dwelling on the Platform: Housing Access and Equity in the Digital
            Society
          </em>{" "}
          investigates how cultural and economic changes brought about by
          digital platforms and on-demand technologies are impacting the
          physical and social fabric of cities, in particular the home — the
          most elementary unit of the city, and a site at the centre of these
          transformations. It explores the changing nature and conditions of
          dwelling stemming from these new modalities of rent and questions the
          agency of the traditional architect in an environment which is
          increasingly dominated by invisible and insidious digital practices.
          This body of work suggests that the ascendent form of platform
          capitalism need not be taken as given, and that the recalibration of
          these tools and their effects is a matter of design. Could the
          platform play a part in making housing more equitable and accessible?
          Through a strategic re-engineering of these spatial technologies and
          their potential for redistribution, <em>Dwelling on the Platform</em>{" "}
          seeks to redefine a role for the architect in the post-digital city
          towards reclaiming the city as an emancipatory project, and housing as
          a common good.
        </PostParagraph>
      </PostParagraphContainer>
      <Footer />
    </MainContainer>
  );
};

export default Dwelling;
