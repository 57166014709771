import React from "react";
import {
  useDocumentTitle,
  ProjectAlexanderSheridanHeader,
  MainContainer,
  PostParagraph,
  FirstPostParagraph,
  HomeParagraph,
  PostParagraphContainer,
  PostTitleContainer,
  CreditLine,
  CreditLineContainer,
} from "../project-page/ProjectPageElements.js";
import Footer from "../../components/Footer.js";
import PostSlider from "../../components/post/PostSlider.js";

import image1 from "../../images/publications/dwelling/1_Dwellingontheplatform.jpg";
import image2 from "../../images/publications/dwelling/2_Dwellingontheplatform.jpg";
import image3 from "../../images/publications/dwelling/3_Dwellingontheplatform.jpg";
import image4 from "../../images/publications/dwelling/4_Dwellingontheplatform.jpg";
import image5 from "../../images/publications/dwelling/5_Dwellingontheplatform.jpg";
import image6 from "../../images/publications/dwelling/6_Dwellingontheplatform.jpg";

const PostTitle = "Dwelling on the Platform";

const images = [
  {
    image: image1,
    caption: <em>Image Credits: Warren Taylor</em>,
  },
  {
    image: image2,
    caption: <em>Image Credits: Michael Pham</em>,
  },
  {
    image: image3,
    caption: <em>Image Credits: Tobias Titz</em>,
  },
  {
    image: image4,
    caption: <em>Image Credits: Tobias Titz</em>,
  },
  {
    image: image5,
    caption: <em>Image Credits: Tobias Titz</em>,
  },
  {
    image: image6,
    caption: <em>Image Credits: Warren Taylor</em>,
  },
];

const Dwelling = () => {
  useDocumentTitle(PostTitle);
  return (
    <MainContainer>
      <HomeParagraph>
        <ProjectAlexanderSheridanHeader />{" "}
        <PostTitleContainer>
          {PostTitle}: Housing Access and Equity in the Digital Society
        </PostTitleContainer>{" "}
        is the exegesis that accompanies the Dwelling on the Platform (2023)
        exhibition.{" "}
      </HomeParagraph>
      <PostSlider images={images}></PostSlider>
      <PostParagraphContainer>
        <FirstPostParagraph>
          The publication was designed in collaboration with Warren Taylor.{" "}
          <em>Dwelling on the Platform</em> reflects on a series of creative
          works that explore the conditions, opportunities, and contingencies of
          architecture in the era of the platform.
        </FirstPostParagraph>
        <PostParagraph>
          The four books that comprise the publication extend this investigation
          to test the impacts on-demand platforms on architecture publishing
          processes. Investigating different modes of labour and production, the
          four parts have been produced using various print bureaus. Part 1 was
          printed by a local, family-owned digital printer; Part 2 by an
          internet-based print-on demand service; Part 3 and 4 by the university
          print workshop, managed by the student union. The standard trade book
          size (200 x 250mm) of the print-on demand service established a set
          format for the publications and content was or ganised and scaled
          according to a 64-page constraint. In this way, the books become
          registers for the differences in time, cost, quality and distribution
          between cooperative, commercial on-demand and local printing presses
        </PostParagraph>
      </PostParagraphContainer>
      <CreditLineContainer>
        <CreditLine>
          Graphic design: <em>Warren Taylor</em>
        </CreditLine>
      </CreditLineContainer>

      <Footer />
    </MainContainer>
  );
};

export default Dwelling;
