import { useEffect } from "react";
import styled from "styled-components";
import styles from "../../styles.js";
import AlexanderSheridanLink, {
  RandomColour,
} from "../../components/AlexanderSheridanLink.js";

export const useDocumentTitle = (title) => {
  useEffect(() => {
    document.title = title;
  }, [title]);
};

export const ProjectAlexanderSheridanHeader = AlexanderSheridanLink;
export const ProjectRandomColour = RandomColour;

export const MainContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const PostParagraphContainer = styled.div`
  ${styles.PostParagraphContainerM};

  @media screen and (min-width: 700px) {
    ${styles.PostParagraphContainer};
  }
`;

export const HomeParagraph = styled.p`
  ${styles.PostBigParagraph};
`;

export const PostParagraph = styled.p`
  ${styles.PostParagraphM}
  @media screen and (min-width: 700px) {
    ${styles.PostParagraph};
  }
`;

export const FirstPostParagraph = styled.p`
  ${styles.PostParagraphM}
  text-indent: 0rem !important;
  @media screen and (min-width: 700px) {
    ${styles.PostParagraph};
    text-indent: 0rem !important;
  }
`;

export const PostTitleContainer = styled.span`
  ${styles.PostTitleContainer}
`;

export const CreditLineContainer = styled.div`
  margin-left: none;
  margin-right: none;
  margin-bottom: 5rem;

  @media screen and (min-width: 700px) {
    margin-left: ${styles.HomeIndent};
    margin-right: ${styles.HomeIndent};
  }
`;

export const CreditLine = styled.span`
  display: block;
  margin-bottom: 0.5rem;
  ${styles.PostParagraphM}
  text-indent: 0rem !important;
  font-size: 1.1rem !important;
  font-weight: 400;
  @media screen and (min-width: 700px) {
    ${styles.PostParagraph};
    text-indent: 0rem !important;
    font-size: 0.9rem !important;
  }
`;

export const NextProjectContainer = styled.span``;

export const NextProjectSymbol = () => {
  return <span> &gt;</span>;
};

export const NextProjectLink = styled.a`
  ${styles.PostBigParagraph};
  color: ${ProjectRandomColour};
  text-decoration: underline;
`;

export const ProjectLinkContainer = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: flex-end;
  position: relative;
  top: 0.3rem;
`;
