export const AwardsList = [
  {
    subtitle: "Winner: ",
    title: "AIA 2021 Emerging Architects’ Prize (Victoria)",
    href: "https://www.architecture.com.au/awards/2021-awards/2021-victorian-architecture-awards-winners",
    to: "/",
    target: "_blank",
  },
  {
    subtitle: "Shortlisted: ",
    title: "2021 ArchitectureAU Prize for Unbuilt Work",
    href: "https://architectureau.com/articles/2021-aa-prize-for-unbuilt-work-shortlist/",
    to: "/",
    target: "_blank",
  },
  {
    subtitle: "Winner: ",
    title:
      "2020 Monash Art Design Architecture Education Award for Teaching Excellence",
    href: null,
  },
  {
    subtitle: "Recipient: ",
    title:
      "2020 Australian Broadcasting Corporation & Australia Council for the Arts’ Top 5 Arts Media Residency",
    href: "https://about.abc.net.au/press-releases/inaugural-abc-top-5-arts-announced/",
    to: "/",
    target: "_blank",
  },
  {
    subtitle: "Winner: ",
    title:
      "2012 Bates Smart Award for Architecture in Media – National Award. POST Magazine Editorial Team: Jacqui Alexander, Ben Sheridan, Dan Salmon, Matt Nowicki, Tom Morgan.",
    href: null,
  },
];
