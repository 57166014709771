import React from "react";
import styled from "styled-components";

const Container = styled.header``;

const Footer = () => {
  return <Container></Container>;
};

export default Footer;
